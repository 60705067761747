<template lang='pug'>
	.d-flex.flex-wrap.justify-content-between.moderation(v-if="project")

		.card-basic.mb-3(v-if="status!=='BLOCKED'")
			.title(v-html="title" v-if="title")
			.description.text-grey.mb-3(v-if="description" v-html="description")
			.reasons(v-if="reasons")
				.item(v-for="item in reasons" v-html="item")
			.description.text-grey.mb-3(v-if="reasons" v-html="$t('moderation.reasonsDescription')" )
			b-badge.badge(v-if="badge" :variant='badge.variant' pill :class="{'mb-3':isSubmitShow}") {{ badge.text }}
			.btn.btn-primary(v-if="isSubmitShow" :disabled="!isSubmitEnable" @click="requestModerationNoty" v-html="$t('moderation.requestButton')" )
		.row.mb-3(v-else)
			.col-md-6
				.card-basic.card-basic__left
					.title(v-html="title")
					.reasons(v-if="reasons")
						.item(v-for="item in reasons" v-html="item")
					b-badge.badge.mb-auto(v-if="badge" :variant='badge.variant' pill) {{ badge.text }}
			.col-md-6
				.card-basic
					.text.text-grey.mb-3( v-html="$t('moderation.blockDescription')" )
					a.d-block.mb-3.text-decoration-none(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
						pw-btn(:text="$t('h1.telegram')" :icon="'icon-telegram'")
					pw-btn-mail

		.card-basic.mb-3
			.title(v-html="$t('moderation.blockRuleTitle')")
			.borderedBlock(v-for="item in $t('moderation.blockRuleList')" v-html="item" )

		.card-basic.mb-3
			.title(v-html="$t('moderation.blockDisabledTitle')")
			.description
				.borderedBlock
					ol
						li(v-for="item in $t('moderation.blockDisabledList')" v-html="item")
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import PwBtn from "@/components/Buttons/PwBtn.vue";
import PwBtnMail from "@/components/Buttons/PwBtnMail.vue";
import { STORAGE } from '@/api/storage'

export default {
	name: 'moderation',
	components: {
		PwBtn,
		PwBtnMail
	},
    data: () => ({
        isPending: false,
    }),
	computed:{
		...mapGetters({
			project: 'project/opened',
			isPendingProject: 'project/isPending'
		}),
		title() {
			if (!this.status || ['UNBLOCKED'].includes(this.status)) {
				if (!this.projectIsCompleted) {
					return this.$t('moderation.titleNotCompleted');
				} else {
					return this.$t('moderation.titleCompleted');
				}
			}

			switch (this.status) {
				case"NEW": return this.$t('moderation.titleNew');
				case"REVOKED": return this.$t('moderation.titleRevoked');
				case"BLOCKED": return this.$t('moderation.titleRevoked');
			}
		},
		projectIsCompleted(){
			if(!this.me.hasFilledAccount())return false
			return this.project.isCompleted()
		},
		status(){
			return this.project?.moderationRequestLast?.status;
		},
		reasons(){
            if(!['BLOCKED','REVOKED'].includes(this.status))return ;
			return this.project?.moderationRequestLast?.reasons?.map(itm => this.$t(itm));
		},
		description(){
			switch (this.status) {
				case"NEW":return this.$t('moderation.descriptionNew');
			}
		},
		badge(){
			const mod=this.project.moderationStatusBadge();

			if (mod) {
				mod.text = this.$t(mod.text);
				return mod;
			}
		},
		isSubmitEnable() {
			return this.project && !this.isPending && this.projectIsCompleted &&( !this.status || ['REVOKED','UNBLOCKED'].includes(this.status));
		},
		isSubmitShow() {
			return !['APPROVED'].includes(this.status);
		}
	},
	methods:{
		...mapActions({
			requestModeration: 'project/requestModeration'
		}),
        requestModerationNoty(){
            this.isPending=true
            this.requestModeration().then(()=>{
	            STORAGE.setStatusModalSuccess(this.project?.id, 1);
                this.$notify("success", this.$t('moderation.requestNoty'));
            }).finally(()=>{
                this.isPending=false
			})
        },
	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.moderation {
	max-width: 935px;

	.row .card-basic{
		height: 100%;

		&__left {
			background: var(--red-light-bg-color)!important;

			&::v-deep {
				.badge-danger {
					background-color: var(--red-text-color);
					color: var(--wr-text-color);
				}
			}

			@include respond-below(sm) {
				margin-bottom: 14px;
				height: auto;
			}
		}
	}
	.reasons{
		color: var(--red-text-color);
		margin-bottom: 10px;
		padding-left: 1.5rem;

		.item {
			padding-bottom: 10px;
			list-style: disc;
			display: list-item;
		}
	}
	.card-basic{
		width: 100%;

		.title {
			font-size: 16px;
			font-weight: 600;
			line-height: 24px;
			margin-bottom: 20px;
		}

		.badge {
			border-radius: 6px;
			font-size: 14px;
			display: inline-flex;
			padding: 3px 8px;
			&.mb-3{
				margin-bottom: 20px!important;
			}
		}

		.btn {
			max-width: 463px;
			&[disabled] {
				opacity: 0.5;
				pointer-events: none;
			}
		}

		.borderedBlock{
			border-radius: 8px;
			border: 1px solid var(--border-dropdown-color);
			padding: 20px;
			margin-bottom: 10px;
			font-size: 1rem;
			line-height: 1.2;
		}
		ol {
			padding: 0 0 0 1rem;
			margin: 0;

			li{
				margin-bottom: .5rem;
			}
		}
	}
}
</style>
